import store from 'store2';
import i18next from 'i18next';
import { corpusTranslations } from '../i18n';

// eslint-disable-next-line import/no-mutable-exports
export let corpus;
// eslint-disable-next-line import/no-mutable-exports
export let numItems;

export function processCSV() {
  const csvAssets = {
    test: corpusTranslations[i18next.language].test,
    practice: corpusTranslations[i18next.language].practice,
  };

  const shuffle = (array) => {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i -= 1) {
      const j = Math.floor(Math.random() * (i + 1)); // random index from 0 to i

      // swap elements array[i] and array[j]
      // use "destructuring assignment" syntax
      // eslint-disable-next-line no-param-reassign
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };

  const transformCSV = (csvInput) => {
    const csv = csvAssets[csvInput];

    return csv.reduce((accum, row) => {
      const newRow = {
        task: row.task,
        trial_type: row.trial_type,
        itemId: csvInput === 'practice' ? row.trial_num : row.original_name,
        stimulus: row.stim,
        goal: row.goal,
        foil1: row.foil1,
        foil2: row.foil2,
        arrayShow: shuffle([row.goal, row.foil1, row.foil2]),
        quest: row.quest,
        instr: row.instr,
        feedback_conf: csvInput === 'practice' ? row.feedback_conf : null,
        feedback_foil1: csvInput === 'practice' ? row.feedback_foil1 : null,
        feedback_foil2: csvInput === 'practice' ? row.feedback_foil2 : null,
      };
      accum.push(newRow);
      return accum;
    }, []);
  };

  function countTrialTypes(document) {
    // Initialize counts for each trial type
    let fsmCount = 0;
    let lsmCount = 0;
    let delCount = 0;

    // Iterate through each row in the document
    document.forEach((row) => {
      // Check the trial type of the row
      switch (row.trial_type) {
        case 'FSM':
          fsmCount += 1;
          break;
        case 'LSM':
          lsmCount += 1;
          break;
        case 'DEL':
          delCount += 1;
          break;
        default:
          // Handle other trial types if needed
          break;
      }
    });

    // Return an object containing the counts for each trial type
    return {
      FSM: fsmCount,
      LSM: lsmCount,
      DEL: delCount,
    };
  }

  const test = countTrialTypes(csvAssets.test);
  const practice = countTrialTypes(csvAssets.practice);

  if (!store.session('config')?.numTestItems) {
    numItems = {
      numItemsFSM: test.FSM,
      numItemsLSM: test.LSM,
      numItemsDEL: test.DEL,
    };
  } else {
    numItems = {
      numItemsFSM: store.session('config').numTestItems,
      numItemsLSM: store.session('config').numTestItems,
      numItemsDEL: test.DEL > 0 ? store.session('config').numTestItems : 0,
    };
  }

  // eslint-disable-next-line import/prefer-default-export
  corpus = {
    test_FSM: transformCSV('test').slice(0, test.FSM),
    test_LSM: transformCSV('test').slice(test.FSM, test.FSM + test.LSM),
    test_DEL: transformCSV('test').slice(test.FSM + test.LSM, test.FSM + test.LSM + test.DEL),
    practice_FSM: transformCSV('practice').slice(0, practice.FSM),
    practice_LSM: transformCSV('practice').slice(practice.FSM, practice.FSM + practice.LSM),
    practice_DEL: transformCSV('practice').slice(
      practice.FSM + practice.LSM,
      practice.FSM + practice.LSM + practice.DEL,
    ),
  };

  if (i18next.language === 'es') {
    corpus.test_FSM = shuffle(corpus.test_FSM);
    corpus.test_LSM = shuffle(corpus.test_LSM);
    corpus.test_DEL = shuffle(corpus.test_DEL);
  }
}
