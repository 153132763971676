import jsPsychCallFunction from '@jspsych/plugin-call-function';
import store from 'store2';
import i18next from 'i18next';
import { jsPsych } from './jsPsych';
import { mediaAssets, paValidityEvaluator } from './experiment';
import './i18n';
import { corpus, numItems } from './config/corpus';

const sentryFeedback = document.querySelector('#sentry-feedback');

export const saveTrialData = (data, source) => {
  let response;

  if (source === 'button') {
    response = store.session('currentStimulus').arrayShow[data.button_response];
  } else {
    response = store.session('currentStimulus').arrayShow[data.response];
  }

  store.session.set('feedbackImage', mediaAssets.images[response]);
  store.session.transact('trialNumBlock', (oldVal) => oldVal + 1);

  if (store.session('currentStimulus').task === 'test') {
    store.session.transact('trialNumTotal', (oldVal) => oldVal + 1);
  }

  if (response === store.session('currentStimulus').goal) {
    store.session.set('feedbackAudio', store.session('currentStimulus').feedback_conf);
    store.session.set('response', 1);
    store.session.set('incorrectCounter', 0);
  } else {
    store.session.set('response', 0);
    if (response === store.session('currentStimulus').foil1) {
      store.session.set('feedbackAudio', store.session('currentStimulus').feedback_foil1);
    } else {
      store.session.set('feedbackAudio', store.session('currentStimulus').feedback_foil2);
    }
    store.session.transact('incorrectCounter', (oldVal) => oldVal + 1);
  }

  paValidityEvaluator.addResponseData(data.rt, data.response, store.session('response'));

  jsPsych.data.addDataToLastTrial({
    subtask: store.session('currentStimulus').trial_type,
    trialNumBlock: store.session('trialNumBlock'),
    trialNumTotal: store.session('trialNumTotal'),
    corpusId: store.session('currentStimulus').task,
    stim: store.session('currentStimulus').stimulus,
    goal: store.session('currentStimulus').goal,
    itemId: store.session('currentStimulus').itemId,
    correct: store.session('response'),
    response: response,
    pid: store.session.get('config').pid,
  });
};

export const practiceStoppingRule = (whichBlock) => {
  if (store.session('currentCorpusIndex') === 1) {
    if (store.session('response') === 1 && store.session('trialNumBlock') !== 3) {
      store.session.set('currentStimulus', corpus[whichBlock][store.session('currentCorpusIndex')]);
      store.session.transact('currentCorpusIndex', (oldVal) => oldVal + 1);
    }
    if (store.session('trialNumBlock') === 3) {
      store.session.set('incorrectCounter', 0);
      store.session.set('keepBlock', false);
      store.session.set('currentStimulus', corpus[whichBlock][store.session('currentCorpusIndex')]);
      store.session.transact('currentCorpusIndex', (oldVal) => oldVal + 1);
    }
    return true;
  }
  // second item
  if (store.session('response') === 1) {
    store.session.set('keepBlock', true);
    return false;
  }
  return store.session('incorrectCounter') !== 2;
};

export const stoppingRule = () => {
  const kResponses = store.session('kResponses');
  kResponses.push(store.session('response'));

  if (kResponses.length > 6) {
    kResponses.shift();
  }
  store.session.set('kResponses', kResponses);
  // Stopping Rule:
  const sumCorrect = kResponses.reduce((a, b) => a + b, 0);
  // console.log(store.session("trialNumBlock"), kResponses, store.session("incorrectCounter"));
  if (store.session('trialNumBlock') >= 6) {
    if (store.session('incorrectCounter') === 3 || sumCorrect <= 2) {
      store.session.set('incorrectCounter', 0);
      store.session.set('kResponses', []);
      jsPsych.endCurrentTimeline();
    }
  }
};

export const audioSetup = {
  type: jsPsychCallFunction,
  async: true,
  func: (done) => {
    const displayElement = document.getElementById('jspsych-content');

    displayElement.innerHTML = `<h1> ${i18next.t('audioSetup')} </h1> 
      <img draggable="false" class="instructionCanvasNS" src="${mediaAssets.images.goBlink}" alt="canvas 1">`;

    function initAudioFiles() {
      jsPsych.pluginAPI.audioContext();
      done();
    }

    document.addEventListener('click', initAudioFiles, { once: true });

    // Hide sentry feedback report button
    if (sentryFeedback) {
      sentryFeedback.style.display = 'none';
    }
  },
};

export const waitFor = (conditionFunction) => {
  const poll = (resolve) => {
    if (conditionFunction()) resolve();
    // eslint-disable-next-line no-unused-vars
    else setTimeout((_) => poll(resolve), 400);
  };

  return new Promise(poll);
};

export const updateProgressBar = (num) => {
  let set;
  if (store.session('config')?.numTestItems) {
    set = store.session('config').numTestItems * 3;
  } else {
    set = numItems.numItemsFSM + numItems.numItemsLSM + numItems.numItemsDEL;
  }
  if (num) {
    jsPsych.setProgressBar(num / (set + 1));
  } else {
    const curr_progress_bar_value = jsPsych.getProgressBarCompleted();
    jsPsych.setProgressBar(curr_progress_bar_value + 1 / (set + 1));
  }
};
