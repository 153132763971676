/* eslint-disable import/prefer-default-export */
import _mapValues from 'lodash/mapValues';
import _omit from 'lodash/omit';
import _reduce from 'lodash/reduce';
import * as Papa from 'papaparse';
import store from 'store2';
import { getGrade } from '@bdelab/roar-utils';

export class RoarScores {
  constructor() {
    this.tableURL = 'https://storage.googleapis.com/roar-pa/scores/pa_lookup_v3.csv';
    this.lookupTable = [];
    this.tableLoaded = false;
  }

  async initTable() {
    return new Promise((resolve, reject) => {
      const ageInMonths = store.session.get('config').userMetadata?.ageMonths;
      const grade = getGrade(store.session.get('config').userMetadata?.grade);

      // eslint-disable-next-line eqeqeq
      if (ageInMonths == undefined && grade == undefined) reject();

      const ageMin = 48;
      const ageMax = 144;

      this.ageForScore = ageInMonths;
      if (ageInMonths < ageMin) this.ageForScore = ageMin;
      if (ageInMonths > ageMax) this.ageForScore = ageMax;

      Papa.parse(this.tableURL, {
        download: true,
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        step: (row) => {
          if (grade && grade >= 6) {
            if (grade === Number(row.data.grade)) {
              this.lookupTable.push(_omit(row.data, ['', 'X']));
            }
          } else if (this.ageForScore === Number(row.data.ageMonths)) {
            this.lookupTable.push(_omit(row.data, ['', 'X']));
          }
        },
        complete: () => {
          this.tableLoaded = true;
          resolve();
        },
      });
    });
  }

  /**
   * This function calculates computed scores given raw scores for each subtask.
   *
   * The input raw scores are expected to conform to the following interface:
   *
   * interface IRawScores {
   *   [key: string]: {
   *     practice: ISummaryScores;
   *     test: ISummaryScores;
   *   };
   * }
   *
   * where the top-level keys correspond to this assessment's subtasks. If this
   * assessment has no subtasks, then there will be only one top-level key called
   * "composite." Each summary score object implements this interface:
   *
   * interface ISummaryScores {
   *   thetaEstimate: number | null;
   *   thetaSE: number | null;
   *   numAttempted: number;
   *   numCorrect: number;
   *   numIncorrect: number;
   * }
   *
   * The returned computed scores must have that same top-level keys as the input
   * raw scores, and each value must be an object with arbitrary computed scores.
   * For example, one might return the thetaEstimate, a "ROAR score", a percentile
   * score, and a predicted Woodcock-Johnson score:
   *
   * {
   *   fsm: {
   *     roarScore: x;
   *   },
   *   lsm: {
   *     roarScore: y;
   *   },
   *   del: {
   *     roarScore: z;
   *   },
   *   composite: {
   *     roarScore: x + y + z;
   *     standardScore: number;
   *     percentile: number;
   *   }
   * }
   *
   * @param {*} rawScores
   * @returns {*} computedScores
   */
  computedScoreCallback = async (rawScores) => {
    const { taskId } = store.session.get('config');
    if (taskId !== 'pa') return null;

    // This returns an object with the same top-level keys as the input raw scores
    // But the values are the number of correct trials, not including practice trials.
    const computedScores = _mapValues(rawScores, (subtaskScores) => {
      const roarScore = subtaskScores.test?.numCorrect || 0;
      return { roarScore };
    });

    // computedScores should now have keys for lsm, fsm, and del.
    // But we also want to update the total score so we add up all of the others.
    const totalScore = _reduce(_omit(computedScores, ['composite']), (sum, score) => sum + score.roarScore, 0);

    const { userMetadata } = store.session.get('config');
    const grade = getGrade(userMetadata?.grade);
    const { ageForScore } = this;
    // eslint-disable-next-line eqeqeq
    if (grade != undefined || ageForScore != undefined) {
      if (!this.tableLoaded) {
        await this.initTable();
      }

      // Then we find the row in the lookup table that corresponds to the total score.
      let myRow;

      if (grade < 6) {
        myRow = this.lookupTable.find((row) => Number(row.ageMonths) === ageForScore && row.roarScore === totalScore);
      } else {
        myRow = this.lookupTable.find((row) => Number(row.grade) === grade && row.roarScore === totalScore);
      }

      if (myRow !== undefined) {
        // And add columns in the lookup table except for the age and roarScore.
        const { ageMonths, roarScore, grade: rowGrade, ...normedScores } = myRow;

        computedScores.composite = {
          roarScore: totalScore,
          ...normedScores,
        };
      }
      // If the score retrieval doesn't work, still return roarScore.
      else {
        computedScores.composite = {
          roarScore: totalScore,
        };
      }
    }

    return computedScores;
  };
}
