// Firebase imports
import store from 'store2';
import _omitBy from 'lodash/omitBy';
import _isNull from 'lodash/isNull';
import i18next from 'i18next';
import _isUndefined from 'lodash/isUndefined';
import { getAgeData, getGrade } from '@bdelab/roar-utils';
import { getUserDataTimeline } from '../trials/getUserData';
import { jsPsych } from '../jsPsych';
import { RoarScores } from '../scores';
import { paValidityEvaluator } from '../experiment';

// Add this function to create random pid used for demo version later //
const makePid = () => {
  let text = '';
  const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  // eslint-disable-next-line max-len, no-plusplus
  for (let i = 0; i < 16; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
};

export const initStore = (config) => {
  if (store.session.has('initialized') && store.local('initialized')) {
    return store.session;
  }
  store.session.set('currentStimulus', null);
  store.session.set('currentCorpusIndex', 0);
  store.session.set('incorrectCounter', 0);
  store.session.set('trialNumBlock', 0);
  store.session.set('trialNumTotal', 0);
  store.session.set('kResponses', []);
  store.session.set('keepBlock', true);
  store.session.set('userMode', config.userMode);
  store.session.set('numTestItems', config.numTestItems);

  return store.session;
};

const getStoryOption = (opt, grade) => {
  let story;
  if (opt === 'grade-based' && grade !== undefined) {
    if (getGrade(grade) >= 6) {
      story = false;
    } else {
      story = true;
    }
    // Note: we use == instead of === in order to compare against both undefined and null
    // eslint-disable-next-line eqeqeq
  } else if (opt == undefined) {
    story = true;
  } else if (opt === true) {
    story = true;
  } else if (opt === false) {
    story = false;
  } else if (typeof opt === 'string' || opt instanceof String) {
    story = opt?.toLocaleLowerCase() === 'true';
  } else {
    story = true;
  }
  return story;
};

export const initConfig = async (firekit, gameParams, userParams, displayElement) => {
  const cleanParams = _omitBy(_omitBy({ ...gameParams, ...userParams }, _isNull), _isUndefined);

  const {
    userMode,
    userMetadata,
    assessmentPid,
    recruitment,
    skipInstructions,
    consent,
    language = i18next.language,
    labId,
    grade,
    story,
    storyOption,
    birthMonth,
    birthYear,
    numTestItems,
    age,
    ageMonths,
  } = cleanParams;

  let computedStoryParam;

  if (storyOption) {
    computedStoryParam = getStoryOption(storyOption, grade);
  } else {
    computedStoryParam = getStoryOption(story);
  }

  const ageData = getAgeData(birthMonth, birthYear, age, ageMonths);

  if (language !== 'en') i18next.changeLanguage(language);

  const config = {
    taskId: firekit.task.taskId,
    pid: assessmentPid,
    labId,
    userMode: userMode || 'fixed',
    recruitment: recruitment || 'pilot',
    consent: consent ?? true,
    userMetadata: { ...userMetadata, grade, ...ageData },
    startTime: new Date(),
    language,
    firekit,
    skipInstructions: skipInstructions ?? true,
    numTestItems: parseInt(numTestItems, 10),
    story: computedStoryParam,
    displayElement,
  };

  const updatedGameParams = Object.fromEntries(
    Object.entries(gameParams).map(([key, value]) => [key, config[key] ?? value]),
  );

  // Temporarily reset story to whatever the input value was. This is a
  // temporary solution while the ``story`` parameter is being deprecated.
  updatedGameParams.story = story;
  await config.firekit.updateTaskParams(updatedGameParams);

  if (config.pid) {
    await config.firekit.updateUser({
      assessmentPid: config.pid,
      ...config.userMetadata,
    });
  }

  return config;
};

export const initRoarJsPsych = (config) => {
  if (config.displayElement) {
    jsPsych.opts.display_element = config.displayElement;
  }

  // Extend jsPsych's on_finish and on_data_update lifecycle functions to mark the
  // run as completed and write data to Firestore, respectively.
  const extend = (fn, code) =>
    function () {
      // eslint-disable-next-line prefer-rest-params
      fn.apply(fn, arguments);
      // eslint-disable-next-line prefer-rest-params
      code.apply(fn, arguments);
    };

  jsPsych.opts.on_finish = extend(jsPsych.opts.on_finish, () => {
    paValidityEvaluator.markAsCompleted();
    config.firekit.finishRun();
  });

  const roarScores = new RoarScores();
  jsPsych.opts.on_data_update = extend(jsPsych.opts.on_data_update, (data) => {
    if (data.save_trial) {
      config.firekit.writeTrial(data, roarScores.computedScoreCallback.bind(roarScores));
    }
  });

  // Add a special error handler that writes javascript errors to a special trial
  // type in the Firestore database
  window.addEventListener('error', (e) => {
    const { msg, url, lineNo, columnNo, error } = e;

    config.firekit?.writeTrial({
      assessment_stage: 'error',
      lastTrial: jsPsych.data.getLastTrialData().trials[0],
      message: String(msg),
      source: url || null,
      lineNo: String(lineNo || null),
      colNo: String(columnNo || null),
      error: JSON.stringify(error || null),
      correct: null,
    });
  });

  initStore(config);
};

export const initRoarTimeline = (config) => {
  // getLabId,Pid used to be here
  const beginningTimeline = {
    timeline: getUserDataTimeline,
    on_timeline_finish: async () => {
      // eslint-disable-next-line no-param-reassign
      config.pid = config.pid || makePid();
      await config.firekit.updateUser({
        assessmentPid: config.pid,
        labId: config.labId,
        ...config.userMetadata,
      });
    },
  };

  return beginningTimeline;
};
